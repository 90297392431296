import { Component } from 'react'

// import { Link, Route, Redirect } from 'react-router-dom'
import './contact.css'

class Contact extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      error: false,
      message: '',
      process: false,
      submit: false,
      submitDate: '',
      success: false,
    }
  }

  componentDidMount() {
    this.props.initMap(4, 0)
    this.props.heightOn()
    this.props.secChange('contact')
  }

  componentWillUnmount() {
    this.props.heightOff()
  }

  autoHeight = e => {
    const el = e.target
    el.style.height = `${el.scrollHeight}px`
  }

  submitForm = async e => {
    e.preventDefault()
    this.setState({ process: true })
    const data = {}
    data.sender = e.target.sender.value
    data.email = e.target.email.value
    data.subject = e.target.subject.value
    data.message = e.target.message.value
    this.setState({ data })

    const response = await fetch(`/api/contact`, {
      body: JSON.stringify({ data }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }).catch(console.error)

    if (response.status === 200) {
      this.setState(
        {
          message: 'Communication Recieved',
          process: false,
          submitDate: Date.now(),
          success: true,
        },
        () => {
          this.props.toolReset()
        }
      )
    } else if (response.status === 404) {
      this.setState({
        error: true,
        message: 'System failure please try again',
        processing: false,
      })
    }
  }

  render() {
    const { win } = this.props
    const { process, success, message } = this.state
    return (
      <>
        <div className='formHold' style={{ minHeight: win[1], width: win[0] }}>
          <form
            id='contactForm'
            className='fullStage eCenter colGut ptMed'
            onSubmit={this.submitForm.bind(this)}
            style={{ minHeight: win[1] }}
          >
            <div className='fullWidth'>
              <input
                id='sender'
                data-tool={' '}
                className='ptSm pbSm tFooter tWhite bBotSm bWhite'
                placeholder='Sender Identification'
                name='sender'
                required
              ></input>
              <input
                id='email'
                data-tool={' '}
                className='ptSm pbSm tFooter tWhite bBotSm bWhite'
                placeholder='Email Address'
                name='email'
                type='email'
                required
              ></input>
              <input
                id='subject'
                data-tool={' '}
                className='ptSm pbSm tFooter tWhite bBotSm bWhite'
                placeholder='Subject of Communication'
                name='subject'
                required
              ></input>
              {!success && (
                <textarea
                  id='message'
                  className='fullWidth tBig ptSm pbHuge tWhite'
                  name='body'
                  placeholder='Message'
                  onChange={this.autoHeight.bind(this)}
                  required
                ></textarea>
              )}
            </div>
            <div className='submitBut fullWidth eNone'>
              {!success ? (
                <>
                  <div className='fullWidth eCenter  bTopSm bWhite bgBlack'>
                    {!process ? (
                      <button
                        type='submit'
                        className='onNorm tFooter tWhite toggleTypeOn fullWidth eFill ptSm pbSm tLeft'
                        data-tool={`RUN Transmission Protocol`}
                        onMouseEnter={this.props.toolInit}
                        onMouseOut={this.props.toolOff}
                      >
                        {this.props.typeOn('submit', 0)}
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                <div className='mtHuge'>
                  <p className='onNorm tWhite tFooter'>
                    {this.props.typeOn(message, 0)}
                  </p>
                  <p className='onNorm tWhite tFooter mbLrg'>
                    {this.props.typeOn(
                      `success code: ${this.state.submitDate}`,
                      0.4
                    )}
                  </p>
                  <p className='onNorm tWhite tFooter mbLrg'>
                    {this.props.typeOn(`Response Imminent`, 1.2)}
                  </p>
                  <p className='onNorm tWhite tFooter mbLrg'>
                    {this.props.typeOn(`Closing Line`, 1.45)}
                  </p>
                </div>
              )}
              <p class='tWhite tFooter' style={{ marginTop: 40, opacity: 1 }}>
                <span style={{ opacity: 0.5 }}>Try </span>
                <a
                  href='mailto:zetto.plus@gmail.com'
                  style={{ opacity: 1, textDecoration: 'underline' }}
                >
                  zetto.plus@gmail.com
                </a>
                <span style={{ opacity: 0.5 }}>
                  {' '}
                  if you don’t hear back from me via the website form, just in
                  case (email client isn’t always working).
                </span>
              </p>
            </div>
          </form>
        </div>
      </>
    )
  }
}

export default Contact
